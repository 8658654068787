<template>
  <div v-cloak>
    <h3 class="pb-3">{{ $t('aside.MerchantList') }}</h3>
    <form @submit.prevent="doSearch">
      <div class="search-form">
        <p>
          <i class="fa fa-search"></i> {{ $t("Tsearch.title") }}：
        </p>
        <div>
          <div class="p-3 row">
            <div class="col-sm-6 col-xl-4 search-column">
              <label for="tenantId"> {{ $t("Tsearch.tenantId") }}：</label>
              <input type="text" class="form-control" id="tenantId" name="tenantId" v-model="tenantId" required>
            </div>
            <!-- <div class="col-sm-6 col-xl-4 search-column">
                      <label for="parentId"> {{ $t("Tsearch.parentId") }}：</label>
                      <input type="text" class="form-control" id="parentId" name="parentId" v-model="parentId">
                  </div> -->
            <div class="col-sm-6 col-xl-4 search-column">
              <label for="accountId"> {{ $t("Tsearch.accountId") }} ：</label>
              <input type="text" class="form-control" id="accountId" name="accountId" v-model="accountId">
            </div>
            <div class="col-sm-6 col-xl-4 search-column">
              <label for="accountName"> {{ $t("Tsearch.userAccount") }}：</label>
              <input type="text" class="form-control" id="accountName" name="accountName" v-model="accountName">
            </div>
            <div class="col-sm-6 col-xl-4 search-column">
              <label for="displayName"> {{ $t("Tsearch.userName") }}：</label>
              <input type="text" class="form-control" id="displayName" name="displayName" v-model="displayName">
            </div>
            <div class="col-sm-6 col-xl-4 search-column">
              <label for="merchantType"> {{ $t("Tsearch.merchantType") }}：</label>
              <select class="form-control" id="merchantType" name="merchantType" v-model="listAgent">
                <option v-for="item in merchantTypeList" v-bind:key="item.value">
                  {{ item.text }}
                </option>
              </select>
            </div>
            <div class="col-sm-6 col-xl-3 search-column">
              <label for="searchStatus">{{ $t("Tsearch.accountStatus") }}：</label>
              <select v-model.number="accountStatus" class="form-control">
                <option v-for="item in accountStatusOptions" v-bind:key="item.value">{{ item.name }}</option>
              </select>
            </div>
            <div class="col-12 selectTimeType"></div>
            <div class="col-8 search-column-time">
              <label for="parentId"> {{ $t("Tsearch.balance") }}：</label>
              <input type="text" class="form-control" id="balanceUpBound" name="balanceUpBound" v-model="balanceUpBound"
                :placeholder="$t('merchantListModal.balanceUpBound')">
              <label> {{ $t("Tsearch.creatTimeTO") }}</label>
              <input type="text" class="form-control" id="balanceLowBound" name="balanceLowBound"
                v-model="balanceLowBound" :placeholder="$t('merchantListModal.balanceLowBound')">
            </div>
          </div>
          <button type="submit" id="searchBtn" name="searchBtn">
            {{ $t('Tsearch.searchBtn') }}
          </button>
        </div>
      </div>
    </form>
    <div v-if="message" class="text-primary pb-2">{{ message }}</div>
    <div class="responsiveTable" style="margin-bottom:15px">
      <table class="table table-striped whiteTable">
        <thead>
          <tr>
            <th>{{ $t("whiteTable.id") }}</th>
            <th>{{ $t("whiteTable.accountName") }}</th>
            <th>{{ $t("whiteTable.sum") }}</th>
            <th>{{ $t("whiteTable.reservedBalance") }}</th>
            <th>{{ $t("whiteTable.balance") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ merchantUser.id }}</td>
            <td>{{ merchantUser.accountName }}</td>
            <td>{{ merchantUser.reservedBalance + merchantUser.balance | money(3) }}</td>
            <td>{{ merchantUser.reservedBalance | money(3) }}</td>
            <td>{{ merchantUser.balance | money(3) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="functionButton">
      <button v-on:click="createMerchant($event, 0)">
        <i class="fa fa-plus"></i>{{ $t("merchantListModal.createMerchant") }}
      </button>
      <button v-if="upLevelId.length > 0" v-on:click="showParent($event, 0)">
        <i class="fa fa-rotate-left"></i>{{ $t("merchantListModal.upLevel") }}
      </button>
      <div v-if="upperLevels.length > 0 && !showParentId" class="d-inline-block ml-3">
        <span v-for="(item, index) in upperLevels" v-bind:key="index">{{ index > 0 ? '>' : '>>' }}{{ item.merchantId
          }}</span>
      </div>
    </div>
    <div class="responsiveTable">
      <table class="table table-striped whiteTable">
        <thead>
          <tr>
            <th>{{ $t("whiteTable.tenantId") }}</th>
            <th>{{ $t("whiteTable.id") }}</th>
            <th>{{ $t("whiteTable.accountName") }}</th>
            <th>{{ $t("whiteTable.displayName") }}</th>
            <th>{{ $t("whiteTable.merchantType") }}</th>
            <th v-if="showParentId">{{ $t("whiteTable.parentId") }}</th>
            <th>{{ $t("whiteTable.balance") }}</th>
            <th>{{ $t("whiteTable.isPrivate") }}</th>
            <th>{{ $t("whiteTable.status") }}</th>
            <th>{{ $t("whiteTable.createTime") }}</th>
            <th>{{ $t("whiteTable.lastLogin") }}</th>
            <th>{{ $t("whiteTable.button") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users" v-bind:key="index">
            <td>{{ user.tenantId }}</td>
            <td>{{ user.id }}</td>
            <td>{{ user.accountName }}</td>
            <td>{{ user.displayName }}</td>
            <td>{{ user.isAgent ? $t("merchantListModal.typeAgent") : $t("merchantListModal.typeMerchant") }}</td>
            <td v-if="showParentId">{{ user.parentId == 0 ? '--' : user.parentId }}</td>
            <td>{{ user.balance | money(3) }}</td>
            <td>{{ user.isPrivate ? $t("modal.yes") : $t("modal.no") }}</td>
            <td>{{ user.status | accountStatus }}</td>
            <td>{{ user.createTime | formatTs }}</td>
            <td>{{ user.lastLogin | formatTs }}</td>
            <td width="20%">
              <button type="button" class="btna" v-on:click="credit($event, index, user.tenantId)">{{
                $t("operate.addpoint")
              }}</button>
              <button type="button" class="btna" v-on:click="edit($event, index, user.id, user.tenantId)">{{
                $t("operate.edit")
              }}</button>
              <button type="button" class="btna" v-on:click="editPayoutIpWhitelist($event, index, user.tenantId)">{{
                $t("merchantListModal.payoutIpWhitelist") }}</button>
              <button type="button" class="btna" v-on:click="updateRates($event, index, user.tenantId)">{{
                $t("operate.updateRates")
              }}</button>
              <!-- <button type="button" class="btna" v-on:click="showKeys($event, index)">{{ $t("operate.showKeys")
                }}</button>
              <button type="button" class="btna" v-on:click="updatePassword($event, index)">{{
                $t("operate.updatePassword") }}</button>
              <button type="button" class="btna" v-on:click="updateTransactPassword($event, index)"
                v-if="user.transactPasswordLastSet > 0">{{ $t("operate.updateTransactPassword") }}</button>
              <button type="button" class="btna" v-on:click="showChildren($event, index)" v-if="user.isAgent">{{
                $t("operate.showChildren") }}</button>
              <button type="button" class="btna" v-on:click="createMerchant($event, user.id)" v-if="user.isAgent">{{
                $t("operate.createSubMerchant") }}</button>
              <button type="button" class="btna" v-on:click="showLoginLogs($event, index)">{{
                $t("operate.showLoginLogs") }}</button>
              <button type="button" class="btna" v-on:click="showBankAccounts($event, index)">{{
                $t("operate.showBankAccounts") }}</button>
              <button type="button" class="btna" v-on:click="showPayoutsModal($event, index)">{{
                $t("merchantListModal.payoutLogs") }}</button>
              <button type="button" class="btna" v-on:click="showSuccesRates($event, index)">{{
                $t("operate.showSuccesRates") }}</button>
              <button type="button" class="btna" v-on:click="showCreatePayment($event, index)">{{
                $t("operate.showCreatePayment") }}</button>
              <button type="button" class="btna" v-on:click="confirmDisableOtp(index)">{{ $t("operate.closeOTP")
                }}</button>
              <button type="button" class="btna" v-on:click="confirmDisablePayoutAuth(index)">{{
                $t("operate.closePayoutAuth") }}</button> -->
            </td>
            <td width="20%" render-if="isOperator">
              <button type="button" class="btna" v-on:click="showChildren($event, index)" v-if="user.isAgent">{{
                $t("operate.showChildren") }}</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="container text-center mt-3">
        <a v-if="currentPage > 1" class="text-secondary pl-2" href="javascript:void(0);" v-on:click="pagePrev">{{
          $t("operate.pagePrev") }}</a>
        <span v-else class="text-secondary pl-2">{{ $t("operate.pagePrev") }}</span>
        <span class="text-primary">{{ currentPage }}/{{ totalPages }} ({{ $t("operate.totalRecords1") }}{{ totalRecords
          }}{{
            $t("operate.totalRecords2") }})</span>
        <a v-if="currentPage < totalPages" class="text-secondary pl-2" href="javascript:void(0);"
          v-on:click="pageNext">{{ $t("operate.pageNext") }}</a>
        <span v-else class="text-secondary pl-2">{{ $t("operate.pageNext") }}</span>
      </div>
    </div>

    <!-- Modle -->

    <div class="modal fade" id="creditModal" tabindex="-1" role="dialog" aria-labelledby="creditModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="creditModalLabel">{{ $t("dealerList.addPoint") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <table class="table table-bordered modalTable">
                <tr>
                  <td class="title">{{ $t("whiteTable.tenantId") }}：</td>
                  <td>
                    <input type="number" id="creditTenantId" v-model.number="creditTenantId" class="form-control"
                      aria-describedby="creditHelp" disabled />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("dealerList.showPoint") }}：</td>
                  <td>
                    <input type="number" id="creditToAdd" v-model.number="creditAmount" class="form-control"
                      aria-describedby="creditHelp" />
                    <small id="creditHelp" class="form-text text-muted">{{ $t("dealerList.showPointAlert") }}</small>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("dealerList.addPointNote") }}：</td>
                  <td>
                    <input type="text" id="creditToAddNote" v-model="creditMemo" class="form-control"
                      aria-describedby="creditHelp" />
                    <small id="creditHelp" class="form-text text-muted">{{ $t("dealerList.addPointNoteAlert") }}</small>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("operate.twoStepTitle") }}：</td>
                  <td>
                    <input type="text" id="creditVerificationTicket" v-model="verificationTicket" class="form-control"
                      aria-describedby="creditHelp" />
                    <small class="form-text text-muted">{{ $t("operate.twoStepNote") }}</small>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("login.inputCaptcha") }}：</td>
                  <td>
                    <input type="text" id="inputCaptcha" autocomplete="off" v-model="captcha" name="captcha"
                      class="form-control" :placeholder="$t('login.inputCaptcha')" required autofocus>
                    <img v-bind:src="captchaUrl" id="inputCaptchaImg" v-on:click="refreshCaptcha" style="height:60px"
                      class="rounded" />
                  </td>
                </tr>
              </table>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="grayBtn" data-bs-dismiss="modal">{{ $t("modal.cancel") }}</button>
            <button type="button" v-bind:disabled="processingRequest" class="yellowBtn" v-on:click="doCredit">{{
              $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="updatePasswordModal" tabindex="-1" role="dialog"
      aria-labelledby="updatePasswordModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="updatePasswordModalLabel">{{ $t("operate.updatePassword") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <table class="table table-bordered modalTable">
                <tr>
                  <td class="title">{{ $t("merchantListModal.newpasswd") }}：</td>
                  <td>
                    <input type="password" id="newPassword" v-model="newPassword" class="form-control" />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.newUserPassword1") }}：</td>
                  <td>
                    <input type="password" id="newPasswordAgain" v-model="newPasswordAgain" class="form-control" />
                  </td>
                </tr>
              </table>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="grayBtn" data-bs-dismiss="modal">{{ $t("modal.cancel") }}</button>
            <button type="button" v-bind:disabled="processingRequest" class="yellowBtn" v-on:click="doUpdatePassword">{{
              $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editMerchantModal" tabindex="-1" role="dialog" aria-labelledby="editMerchantModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editMerchantModalLabel">{{ $t("merchantListModal.editMerchantModalLabel") }}
            </h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form v-if="users.length > 0 && selectedUser >= 0">
              <table class="table table-bordered modalTable">
                <tr>
                  <td class="title">{{ $t("whiteTable.displayName") }}：</td>
                  <td>
                    <input type="text" id="newDisplayName" v-model="editModal.displayName" class="form-control" />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("whiteTable.status") }}：</td>
                  <td>
                    <select v-model.number="editModal.status" class="form-control">
                      <option disabled value="">{{ $t("dealerList.pleaseSelect") }}</option>
                      <option value="1">{{ $t("merchantListModal.statusv1") }}</option>
                      <option value="2">{{ $t("merchantListModal.statusv2") }}</option>
                      <option value="3">{{ $t("merchantListModal.statusv3") }}</option>
                      <option value="4">{{ $t("merchantListModal.statusv4") }}</option>
                      <option value="5">{{ $t("merchantListModal.statusv5") }}</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.isPrivate") }}：</td>
                  <td>
                    <input type="checkbox" id="privateMerchant" v-model="editModal.isPrivate" />
                    <label for="privateMerchant" class="m-0">{{ $t("modal.yes") }}</label>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.merchantType") }}：</td>
                  <td>
                    <select v-model.number="editModal.merchantType" class="form-control">
                      <option value="0">{{ $t("merchantListModal.typeNormal") }}</option>
                      <option value="1">{{ $t("merchantListModal.typeTrustedPartner") }}</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.ipWhitelist") }}：</td>
                  <td>
                    <input type="text" id="ipWhitelist" v-model="editModal.ipWhitelist" class="form-control"
                      autocomplete="off" v-on:click="editIpWhitelist" />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.otpAuth") }}：</td>
                  <td>
                    {{ otpEnable ? 'operate.isEnabled' : 'operate.isDisabled' | translate }}
                    <button class="grayBtn" type="button" v-if="otpEnable" v-on:click="showAuthQRCode">{{
                      $t("operate.checkQRCodeTitle") }}</button>
                    <button class="grayBtn" type="button" v-if="otpEnable" v-on:click="confirmDisableOtp(selectedUser)"
                      render-if="user.HasRole(nameof(UserRole.MerchantOtpWriter))">{{ $t("operate.clickToClose")
                      }}</button>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.payoutAuth") }}：</td>
                  <td>
                    {{ payoutAuthEnabled ? 'operate.isEnabled' : 'operate.isDisabled' | translate }}
                    <button class="grayBtn" type="button" v-if="payoutAuthEnabled"
                      v-on:click="confirmDisablePayoutAuth(selectedUser)"
                      render-if="user.HasRole(nameof(UserRole.MerchantPayoutAuthWriter))">{{ $t("operate.clickToClose")
                      }}</button>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.balanceLimit") }}：</td>
                  <td>
                    <input type="text" class="form-control" id="balanceLimit" v-model.number="editModal.balanceLimit">
                    <small id="balanceLimitHelp" class="form-text text-muted">{{
                      $t("merchantListModal.balanceLimitHelp") }}</small>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.groupId") }}：</td>
                  <td>
                    <label>{{ groupId == 0 ? $t("modal.nothing") : groupId }}</label>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.isUSDT") }}：</td>
                  <td>
                    <label for="usdtMerchant" class="m-0">{{ editModal.isUSDT ? $t("modal.yes") :
                      $t("modal.no") }}</label>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("whiteTable.memo") }}：</td>
                  <td>
                    <input type="text" id="memo" v-model="editModal.memo" class="form-control" />
                  </td>
                </tr>
              </table>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="grayBtn" data-bs-dismiss="modal">{{ $t("modal.cancel") }}</button>
            <button type="button" v-bind:disabled="processingRequest" class="yellowBtn" v-on:click="doEdit">{{
              $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editIpWhitelistModal" tabindex="-1" role="dialog"
      aria-labelledby="editIpWhitelistModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editIpWhitelistModalLabel">{{ $t("merchantListModal.ipWhitelist") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-bordered modalTable">
              <tr v-for="(item, index) in editIpWhitelistData" v-bind:key="index">
                <td>
                  <input type="text" v-model="editIpWhitelistData[index]" maxlength="15" class="form-control"
                    autocomplete="off" />
                </td>
                <td class="fit">
                  <button type="button" class="whiteBtn" v-on:click="editIpWhitelistDelete(index)">{{
                    $t("operate.delete") }}</button>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="text-align:left;background-color:transparent">
                  <button type="button" class="yellowBtn" v-on:click="editIpWhitelistAdd">{{ $t("operate.add")
                    }}</button>
                </td>
              </tr>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="grayBtn" data-bs-dismiss="modal">{{ $t("modal.cancel") }}</button>
            <button type="button" v-bind:disabled="processingRequest" class="yellowBtn"
              v-on:click="doEditIpWhitelist">{{ $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editPayoutIpWhitelistModal" tabindex="-1" role="dialog"
      aria-labelledby="editPayoutIpWhitelistModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editPayoutIpWhitelistModalLabel">{{ $t("merchantListModal.payoutIpWhitelist") }}
            </h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-bordered modalTable">
              <tr v-for="(item, index) in editPayoutIpWhitelistData" :key="index">
                <td>
                  <input type="text" v-model="editPayoutIpWhitelistData[index]" maxlength="15" class="form-control"
                    autocomplete="off" />
                </td>
                <td class="fit">
                  <button type="button" class="whiteBtn" v-on:click="editPayoutIpWhitelistDelete(index)">{{
                    $t("operate.delete") }}</button>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="text-align:left;background-color:transparent">
                  <button type="button" class="yellowBtn" v-on:click="editPayoutIpWhitelistAdd">{{ $t("operate.add")
                    }}</button>
                </td>
              </tr>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="grayBtn" data-bs-dismiss="modal">{{ $t("modal.cancel") }}</button>
            <button type="button" v-bind:disabled="processingRequest" class="yellowBtn"
              v-on:click="doEditPayoutIpWhitelist">{{ $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="merchantKeysModal" tabindex="-1" role="dialog" aria-labelledby="merchantKeysModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="merchantKeysModalLabel">{{ $t("operate.showKeys") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form v-if="users.length > 0 && selectedUser >= 0">

              <table class="table table-bordered modalTable">
                <tr>
                  <td class="title">{{ $t("merchantListModal.Key") }}：</td>
                  <td><input type="text" id="appKey" readonly v-model="users[selectedUser].appKey"
                      class="form-control" /></td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.appSecret") }}：</td>
                  <td><input type="text" id="appSecret" readonly v-model="users[selectedUser].appSecret"
                      class="form-control" /></td>
                </tr>
              </table>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="yellowBtn" data-bs-dismiss="modal">{{ $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="loginLogsModal" tabindex="-1" role="dialog" aria-labelledby="loginLogsModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="loginLogsModalLabel">{{ $t("operate.showLoginLogs") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="search-form">
              <p>
                <i class="fa fa-search"></i> {{ $t("Tsearch.title") }}：
              </p>
              <div>
                <div class="p-3 row">
                  <div class="col-12 search-column-time selectTimeType">
                    <label for="loginLogsstartDate">{{ $t("Tsearch.creatTime") }}：</label>
                    <input type="text" class="form-control" id="loginLogsstartDate" v-model="startDate"
                      autocomplete="off" />
                    <input type="text" class="form-control" id="loginLogsstartTime" v-model="startTime"
                      autocomplete="off" />
                    <label>{{ $t("Tsearch.creatTimeTO") }}</label>
                    <input type="text" class="form-control" id="loginLogsendDate" v-model="endDate"
                      autocomplete="off" />
                    <input type="text" class="form-control" id="loginLogsendTime" v-model="endTime"
                      autocomplete="off" />
                  </div>
                </div>
                <input type="button" :value="$t('Tsearch.searchBtn')" class="searchBtn"
                  v-on:click="loadLoginLogs(0, false)" />
              </div>
            </div>
            <div v-if="message" class="text-primary pb-2">{{ message }}</div>
            <div class="responsiveTable">
              <table class="table table-striped whiteTable">
                <thead>
                  <tr>
                    <th>{{ $t("merchantListModal.logid") }}</th>
                    <th>{{ $t("merchantListModal.logip") }}</th>
                    <th>{{ $t("merchantListModal.logUserAgent") }}</th>
                    <th>{{ $t("merchantListModal.logTimestamp") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(log, index) in loginLogs.items" :key="index">
                    <td>{{ log.id }}</td>
                    <td>{{ log.ip }}</td>
                    <td v-bind:title="log.userAgent">{{ log.userAgent | truncate(30) }}</td>
                    <td>{{ log.timestamp | formatTs }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="container text-center m-0">
                <span v-for="(p, index) in loginLogs.pages" :key="index">
                  <span v-if="p == -1" class="text-secondary pl-2">...</span>
                  <span v-else-if="p == loginLogs.currentPage" class="text-secondary pl-2">{{ p + 1 }}</span>
                  <a v-else href="javascript:void(0);" class="text-primary pl-2" v-on:click="loadLoginLogs(p, false)">{{
                    p + 1 }}</a>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="yellowBtn" data-bs-dismiss="modal">{{ $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="channelsAndRatesModal" tabindex="-1" role="dialog"
      aria-labelledby="channelsAndRatesModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="channelsAndRatesModalLabel">{{ $t("operate.updateRates") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="text-primary pb-2">{{ message }}</div>
            <div class="container p-0">
              <ul class="list-group ml-0 mb-2">
                <li class="list-group-item list-group-item-warning">{{
                  $t("merchantListModal.channelsAndRatesModalAlert1") }}</li>
                <li class="list-group-item list-group-item-info">{{ $t("merchantListModal.channelsAndRatesModalAlert2")
                  }}</li>
              </ul>
            </div>
            <div class="responsiveTable">
              <table class="table table-striped whiteTable">
                <thead>
                  <tr>
                    <th width="15%">{{ $t("merchantListModal.channelname") }}</th>
                    <th width="15%">{{ $t("merchantListModal.channelisEnabled") }}</th>
                    <th width="35%">{{ $t("merchantListModal.channelcommissionRate") }}</th>
                    <th width="35%">{{ $t("merchantListModal.channeladjustment") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(channel, index) in channels" :key="index">
                    <td>{{ channel.name }}</td>
                    <td>{{ channel.isEnabled ? $t("modal.yes") : $t("modal.no") }}</td>
                    <td><input type="number" class="form-control" v-model.number="channel.commissionRate" /></td>
                    <td><input type="number" class="form-control" v-model.number="channel.adjustment" /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="grayBtn" data-bs-dismiss="modal">{{ $t("modal.cancel") }}</button>
            <button type="button" class="yellowBtn" v-bind:disabled="processingRequest" v-on:click="saveRates">{{
              $t("modal.save") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="createMerchantModal" tabindex="-1" role="dialog"
      aria-labelledby="createMerchantModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="createMerchantModalLabel">{{
              $t(newUser.parentId ? "operate.createSubMerchant" : "operate.createMerchant") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="doCreateMerchant">
              <table class="table table-bordered modalTable">
                <tr>
                  <td class="title">{{ $t("whiteTable.tenantId") }}：</td>
                  <td>
                    <input type="text" id="new-tenantId" v-model="newUser.tenantId" class="form-control" required />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("whiteTable.accountName") }}：</td>
                  <td>
                    <input type="text" id="new-accountName" v-model="newUser.accountName" class="form-control"
                      required />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.newUserPassword") }}：</td>
                  <td>
                    <input type="password" id="new-password" v-model="newUser.password" class="form-control" required />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.newUserPassword1") }}：</td>
                  <td>
                    <input type="password" id="new-password1" v-model="newUser.password1" class="form-control"
                      required />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("whiteTable.displayName") }}：</td>
                  <td>
                    <input type="text" id="new-displayName" v-model="newUser.displayName" class="form-control"
                      required />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.newUserLogo") }}：</td>
                  <td>
                    <input type="text" id="new-logo" v-model="newUser.logo" class="form-control" />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.newUserIsPrivate") }}：</td>
                  <td>
                    <input type="checkbox" id="new-privateMerchant" v-model="newUser.isPrivate" />
                    <label class="m-0" for="new-privateMerchant">{{ $t("modal.yes") }}</label>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.merchantType") }}：</td>
                  <td>
                    <select v-model.number="newUser.merchantType" class="form-control">
                      <option value="0">{{ $t("merchantListModal.typeNormal") }}</option>
                      <option value="1">{{ $t("merchantListModal.typeTrustedPartner") }}</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.newUserIsAgent") }}：</td>
                  <td>
                    <input type="checkbox" id="new-agentMerchant" v-model="newUser.isAgent" />
                    <label class="m-0" for="new-agentMerchant">{{ $t("modal.yes") }}</label>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.balanceLimit") }}：</td>
                  <td>
                    <input type="text" class="form-control" id="new-balanceLimit" v-model.number="newUser.balanceLimit">
                    <small id="new-balanceLimitHelp" class="form-text text-muted">{{
                      $t("merchantListModal.balanceLimitHelp") }}</small>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("whiteTable.memo") }}：</td>
                  <td>
                    <input type="text" id="new-memo" v-model="newUser.memo" class="form-control" />
                  </td>
                </tr>
              </table>
              <div class="modal-footer">
                <button type="button" class="grayBtn" data-bs-dismiss="modal" aria-label="Close">
                  {{ $t("modal.cancel") }}
                </button>
                <button type="submit" v-bind:disabled="processingRequest" class="yellowBtn">{{ $t("modal.ok")
                  }}</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="modal fade" id="bankAccountListModal" tabindex="-1" role="dialog"
      aria-labelledby="bankAccountListModalLabel" aria-hidden="true">
      <div class="modal-dialog" style="max-width: 900px;" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="bankAccountListModalLabel">{{ $t("operate.showBankAccounts") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="responsiveTable">
              <table class="table table-striped whiteTable">
                <thead>
                  <tr>
                    <th>{{ $t("whiteTable.id") }}</th>
                    <th>{{ $t("merchantListModal.bankName") }}</th>
                    <th>{{ $t("merchantListModal.holderName") }}</th>
                    <th>{{ $t("merchantListModal.accountNumber") }}</th>
                    <th>{{ $t("merchantListModal.province") }}</th>
                    <th>{{ $t("merchantListModal.city") }}</th>
                    <th>{{ $t("whiteTable.button") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(account, idx) in bankAccounts" :key="idx">
                    <td>{{ account.id }}</td>
                    <td>{{ account.bankName }}</td>
                    <td>{{ account.holderName }}</td>
                    <td>{{ account.accountNumber }}</td>
                    <td>{{ account.province }}</td>
                    <td>{{ account.city }}</td>
                    <td>
                      <button class="btna" v-on:click="removeBankAccount($event, idx)"
                        render-if="user.HasRole(nameof(UserRole.MerchantBankAccountDeleter))">{{ $t("modal.delete")
                        }}</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="yellowBtn" data-bs-dismiss="modal">{{ $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="payoutsModal" tabindex="-1" role="dialog" aria-labelledby="payoutsModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" style="max-width:1000px" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="payoutsModalLabel">{{ $t("merchantListModal.payoutLogs") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="search-form">
              <p>
                <i class="fa fa-search"></i> {{ $t("Tsearch.title") }}：
              </p>
              <div>
                <div class="p-3 row">
                  <div class="col-12 search-column-time selectTimeType">
                    <label for="startDate">{{ $t("Tsearch.creatTime") }}：</label>
                    <input type="text" class="form-control" id="startDate" v-model="startDate" autocomplete="off" />
                    <input type="text" class="form-control" id="startTime" v-model="startTime" autocomplete="off" />
                    <label>{{ $t("Tsearch.creatTimeTO") }}</label>
                    <input type="text" class="form-control" id="endDate" v-model="endDate" autocomplete="off" />
                    <input type="text" class="form-control" id="endTime" v-model="endTime" autocomplete="off" />
                  </div>
                </div>
                <input type="button" :value="$t('Tsearch.searchBtn')" class="searchBtn"
                  v-on:click="loadPayouts(0, false)" />
              </div>
            </div>
            <div class="text-primary pb-2">{{ message }}</div>
            <div class="responsiveTable">
              <table class="table table-striped whiteTable">
                <thead>
                  <tr>
                    <th>{{ $t("whiteTable.id") }}</th>
                    <th>{{ $t("merchantListModal.requestAmount") }}</th>
                    <th>{{ $t("merchantListModal.requestBankName") }}</th>
                    <th>{{ $t("merchantListModal.requestHolderName") }}</th>
                    <th>{{ $t("merchantListModal.requestAccountNumber") }}</th>
                    <th>{{ $t("merchantListModal.requestProvince") }}</th>
                    <th>{{ $t("merchantListModal.requestCity") }}</th>
                    <th>{{ $t("merchantListModal.requestPayoutStatus") }}</th>
                    <th>{{ $t("whiteTable.createTime") }}</th>
                    <th>{{ $t("merchantListModal.requestLastUpdateTime") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(request, index) in payouts.items" :key="index">
                    <td>{{ request.id }}</td>
                    <td>{{ request.amount | money(3) }}</td>
                    <td>{{ request.bankAccount ? request.bankAccount.bankName : "" }}</td>
                    <td>{{ request.bankAccount ? request.bankAccount.holderName : "" }}</td>
                    <td>{{ request.bankAccount ? request.bankAccount.accountNumber : "" }}</td>
                    <td>{{ request.bankAccount ? request.bankAccount.province : "" }}</td>
                    <td>{{ request.bankAccount ? request.bankAccount.city : "" }}</td>
                    <td>{{ request.status | payoutStatus }}</td>
                    <td>{{ request.createTime | formatTs }}</td>
                    <td>{{ request.lastUpdateTime | formatTs }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="container text-center m-0">
                <span v-for="(p, index) in payouts.pages" :key="index">
                  <span v-if="p == -1" class="text-secondary pl-2">...</span>
                  <span v-else-if="p == payouts.currentPage" class="text-secondary pl-2">{{ p + 1 }}</span>
                  <a v-else href="javascript:void(0);" class="text-primary pl-2" v-on:click="loadPayouts(p, false)">{{ p
                    + 1 }}</a>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="yellowBtn" data-bs-dismiss="modal">{{ $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="authImageModal" tabindex="-1" role="dialog" aria-labelledby="authImageModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="authImageModalLabel">{{ $t("operate.checkQRCodeTitle") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div style="text-align:center" id="authImage"></div>
          <div class="modal-footer">
            <button type="button" class="yellowBtn" data-bs-dismiss="modal" aria-label="Close">{{ $t("modal.ok")
              }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="showSuccesRatesModal" tabindex="-1" role="dialog"
      aria-labelledby="showSuccesRatesModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="showSuccesRatesModalLabel">{{ $t("operate.showSuccesRates") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="search-form">
              <p>
                <i class="fa fa-search"></i> {{ $t("Tsearch.title") }}：
              </p>
              <div>
                <div class="p-3 row">
                  <div class="col-12 search-column-time selectTimeType">
                    <label for="showSuccesRateStartDate">{{ $t("Tsearch.creatTime") }}：</label>
                    <input type="text" class="form-control" id="showSuccesRateStartDate" v-model="startDate"
                      autocomplete="off" />
                    <input type="text" class="form-control" id="showSuccesRateStartTime" v-model="startTime"
                      autocomplete="off" />
                    <label>{{ $t("Tsearch.creatTimeTO") }}</label>
                    <input type="text" class="form-control" id="showSuccesRateEndDate" v-model="endDate"
                      autocomplete="off" />
                    <input type="text" class="form-control" id="showSuccesRateEndTime" v-model="endTime"
                      autocomplete="off" />
                  </div>
                </div>
                <input type="button" :value="$t('Tsearch.searchBtn')" class="searchBtn" v-on:click="loadSuccesRates" />
              </div>
            </div>
            <div v-if="message" class="text-primary pb-2">{{ message }}</div>
            <div class="responsiveTable">
              <table class="table table-striped whiteTable">
                <thead>
                  <tr>
                    <th>{{ $t("rechargeReports.channelId") }}</th>
                    <th>{{ $t("rechargeReports.channelName") }}</th>
                    <th>{{ $t("rechargeReports.settlements") }}</th>
                    <th>{{ $t("rechargeReports.cancellations") }}</th>
                    <th>{{ $t("rechargeReports.successRate") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in succesRates.items" :key="index">
                    <td>{{ item.channelId }}</td>
                    <td>{{ item.channelName }}</td>
                    <td>{{ item.settlements }}</td>
                    <td>{{ item.cancellations }}</td>
                    <td>{{ item.successRate | feeRate }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="yellowBtn" data-bs-dismiss="modal">{{ $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="createPayment" tabindex="-1" role="dialog" aria-labelledby="createPaymentModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="createPaymentModalLabel">{{ $t("merchantListModal.createPayment") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form v-if="users.length > 0 && selectedUser >= 0">
              <table class="table table-bordered modalTable">
                <tr>
                  <td class="title">{{ $t("whiteTable.amount") }}：</td>
                  <td>
                    <input type="number" id="newPaymentAmount" v-model.number="payment.amount" class="form-control" />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("whiteTable.merchantId") }}：</td>
                  <td>
                    <input type="number" id="newPaymentMerchantId" v-model.number="payment.merchantId"
                      class="form-control" disabled />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("whiteTable.dealerId") }}：</td>
                  <td>
                    <input type="number" id="newPaymentDealerId" v-model.number="payment.dealerId"
                      class="form-control" />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("whiteTable.type") }}：</td>
                  <td>
                    <select v-model.number="payment.channelId" class="form-control">
                      <option v-for="(type, index) in channelTypeList" :key="index" :value="type.id">{{ type.name }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("operate.twoStepTitle") }}：</td>
                  <td>
                    <input type="text" id="paymentVerificationTicket" v-model="verificationTicket" class="form-control"
                      aria-describedby="creditHelp" />
                    <small class="form-text text-muted">{{ $t("operate.twoStepNote") }}</small>
                  </td>
                </tr>
              </table>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="grayBtn" data-bs-dismiss="modal">{{ $t("modal.cancel") }}</button>
            <button type="button" v-bind:disabled="processingRequest" class="yellowBtn" v-on:click="doCreatePayment">{{
              $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="updateTransactPasswordModal" tabindex="-1" role="dialog"
      aria-labelledby="updateTransactPasswordModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="updateTransactPasswordModalLabel">{{ $t("operate.updateTransactPassword") }}
            </h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <table class="table table-bordered modalTable">
                <tr>
                  <td class="title">{{ $t("merchantListModal.newpasswd") }}：</td>
                  <td>
                    <input type="password" id="newTransactPassword" v-model="newPassword" class="form-control" />
                  </td>
                </tr>
                <tr>
                  <td class="title">{{ $t("merchantListModal.newUserPassword1") }}：</td>
                  <td>
                    <input type="password" id="newTransactPasswordAgain" v-model="newPasswordAgain"
                      class="form-control" />
                  </td>
                </tr>
              </table>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="grayBtn" data-bs-dismiss="modal">{{ $t("modal.cancel") }}</button>
            <button type="button" v-bind:disabled="processingRequest" class="yellowBtn"
              v-on:click="doUpdateTransactPassword">{{ $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="alertModal" tabindex="-1" role="dialog" aria-labelledby="alertModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="alertModalLabel">{{ alertTitle }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h6>{{ alertMsg }}</h6>
          </div>
          <div class="modal-footer">
            <button type="button" class="yellowBtn" data-bs-dismiss="modal">{{ $t("modal.ok") }}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import i18n from '../locales/index';
import store from '@/store/index'


const _accountStatus = [
  { id: 0, name: 'None', transformName: "未知" },
  { id: 1, name: 'Active', transformName: "正常" },
  { id: 2, name: 'Pending', transformName: "审核中" },
  { id: 3, name: 'Lockout', transformName: "锁定" },
  { id: 4, name: 'Disabled', transformName: "禁用" },
  { id: 5, name: 'Removed', transformName: "删除" }
];

function findWithAttr(array, attr, value, property) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      if (!property) {
        return array[i];
      }

      return array[i][property];
    }
  }

  return '';
}

function padNumber(number, digits) {
  var result = number + '';
  while (result.length < digits) {
    result = '0' + result;
  }
  return result;
}

function findTransformName(array, id) {
  return findWithAttr(array, 'id', id, 'transformName');
}

function localDateString(date) {
  return date.getFullYear() + '-' + padNumber(date.getMonth() + 1, 2) + '-' + padNumber(date.getDate(), 2);
}

function localTimeString(date) {
  return padNumber(date.getHours(), 2) + ':' + padNumber(date.getMinutes(), 2) + ':' + padNumber(date.getSeconds(), 2);
}

function formatTs(timestamp) {
  if (timestamp == undefined || timestamp <= 0) {
    return "暂无";
  }
  var d = new Date(timestamp);
  return localDateString(d) + ' ' + localTimeString(d);
};

function webApiCall(url, method, context, callback, data) {
  var request = {
    url: url,
    type: method,
    context: context,
    dataType: 'json',
    headers: {
      'Authorization': 'Bearer ' + store.get('app/globalToken')
    }
  };
  if (data !== undefined && data != null) {
    request.data = JSON.stringify(data);
    request.contentType = 'application/json';
  }
  return $.ajax(request)
    .done(function (data, statusText, jqXHR) {
      callback.apply(this, [jqXHR.status, data]);
    })
    .fail(function (jqXHR) {
      callback.apply(this, [jqXHR.status, jqXHR.responseJSON]);
    });
}

function jsonDeepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

Vue.filter('formatTs', formatTs);

Vue.filter('money', function (number, digits) {
  let result = (number ? number : 0).toLocaleString('en-US', { minimumFractionDigits: (digits ? digits : 0), maximumFractionDigits: (digits ? digits : 0) });
  return result == 0 ? result.replace('-', '') : result;
});

Vue.filter('accountStatus', function (status) {
  return findTransformName(_accountStatus, status);
});

Vue.filter('translate', function (value, arg) {
  return i18n.t((arg ? arg + '.' : '') + value);
});

export default {
  name: 'AddMerChant',
  data: () => ({
    alertTitle: '',
    alertMsg: '',
    save: {
      title: '',
      failMsg: '',
      successMsg: ''
    },
    tenantId: '',
    userId: null,
    parentId: '',
    accountId: '',
    accountName: '',
    displayName: '',
    message: '',
    users: [],
    listAgent: true,
    accountStatus: 0,
    accountStatusOptions: [],
    balanceUpBound: null,
    balanceLowBound: null,
    merchantTypeList: [],
    merchantUser: {
      id: 0,
      accountName: '',
      balance: 0,
      reservedBalance: 0
    },
    upLevelId: [],
    upperLevels: [],
    showParentId: false,
    currentPage: 0,
    totalPages: 0,
    totalRecords: 0,
    editIpWhitelistData: [],
    editPayoutIpWhitelistData: [],
    loginLogs: {
      items: [],
      pages: [],
      currentPage: 0,
      totalPages: 0,
      totalRecords: 0,
      pageToken: '',
      startTime: '',
      endTime: ''
    },
    payouts: {
      items: [],
      pages: [],
      currentPage: 0,
      totalPages: 0,
      totalRecords: 0,
      pageToken: '',
      startTime: '',
      endTime: ''
    },
    channels: [],
    succesRates: {
      items: [],
      startDateAndTime: '',
      endDateAndTime: ''
    },
    creditAmount: 0,
    creditMemo: '',
    verificationTicket: "",
    token: null,
    captcha: "",
    captchaUrl: '',
    processingRequest: false,
    newPassword: '',
    newPasswordAgain: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    newUser: {
      tenantId: 0,
      parentId: 0,
      accountName: '',
      password: '',
      password1: '',
      displayName: '',
      logo: '',
      isPrivate: false,
      balanceLimit: 0,
      memo: ''
    },
    bankAccounts: [],
    editModal: {
      displayName: '',
      status: '',
      isPrivate: '',
      merchantType: 0,
      ipWhitelist: [],
      balanceLimit: 0,
      isUSDT: '',
      memo: ''
    },
    otpEnable: false,
    payoutAuthEnabled: false,
    groupId: 0,
    payment: {},
    channelTypeList: [],
    creditTenantId: 0,
    selectedUser: 0,
  }),
  created() {

  },
  methods: {
    alert: function (title, message) {
      this.alertTitle = title;
      this.alertMsg = message;
      $('#alertModal').modal('show');
    },
    saveError: function () {
      this.alert(this.save.title, this.save.failMsg);
    },
    saveSuccess: function () {
      this.alert(this.save.title, this.save.successMsg);
    },
    saveErrorWithMsg: function (message) {
      this.alert(this.save.title, message);
    },
    pagePrev: function (evt) {
      this.loadData('prev');
    },
    pageNext: function (evt) {
      this.loadData('next');
    },
    showKeys: function (evt, index) {
      this.selectedUser = index;
      $('#merchantKeysModal').modal('show');
    },
    updateRates: function (evt, index, tenantId) {
      this.selectedUser = index;
      this.channels = [];
      this.originalRates = {};
      this.message = '正在加载数据';
      this.creditTenantId = tenantId;
      $('#channelsAndRatesModal').modal('show');
      webApiCall(
        '/api/Channels?_tenantId=' + tenantId,
        'GET',
        this,
        function (status, data) {
          if (status == 200) {
            var channels = [];
            for (var i = 0; i < data.result.length; ++i) {
              channels.push({
                id: data.result[i].id,
                name: data.result[i].name,
                isEnabled: false,
                commissionRate: 0,
                adjustment: 0
              });
            }

            webApiCall(
              '/api/Merchants/' + this.users[this.selectedUser].id + '/Rates?_tenantId=' + this.tenantId,
              'GET',
              this,
              function (s, d) {
                this.message = '';
                if (s == 200) {
                  for (var i = 0; i < d.result.length; ++i) {
                    this.originalRates[d.result[i].channelId] = d.result[i].commissionRate;
                    for (var j = 0; j < channels.length; ++j) {
                      if (d.result[i].channelId == channels[j].id) {
                        channels[j].commissionRate = d.result[i].commissionRate;
                        if (d.result[i].commissionRate < 10000 && d.result[i].commissionRate >= 0) {
                          channels[j].isEnabled = true;
                        }
                        break;
                      }
                    }
                  }
                  this.channels = channels;
                } else {
                  this.errorTitle = this.$t('jsmethod.webApiCall.cannotLoading');
                  this.errorMsg = this.$t('merchantListModal.updateRatesError');
                  $('#channelsAndRatesModal').modal('hide');
                  this.alert(this.errorTitle, this.errorMsg);
                }
              });
          } else {
            this.message = '';
            this.errorTitle = this.$t('jsmethod.webApiCall.cannotLoading');
            this.errorMsg = this.$t('merchantListModal.updatechannelError');
            $('#channelsAndRatesModal').modal('hide');
            this.alert(this.errorTitle, this.errorMsg);
          }
        });
    },
    editPayoutIpWhitelist: function (evt, index, tenantId) {
      this.creditTenantId = tenantId
      this.message = this.$t('jsmethod.webApiCall.loading');
      this.selectedUser = index;
      webApiCall(
        '/api/Merchants/' + this.users[this.selectedUser].id + '/PayoutIpWhitelist' + '?_tenantId=' + tenantId,
        'GET',
        this,
        function (status, data) {
          if (status == 200) {
            this.message = '';
            this.editPayoutIpWhitelistData = jsonDeepClone([]);
            if (data.result.length > 0) {
              for (var arryIndex = 0; arryIndex < data.result.length; arryIndex++) {
                this.editPayoutIpWhitelistData.push(data.result[arryIndex].ip);
              }
            }
            $('#editPayoutIpWhitelistModal').modal('show');
          } else {
            this.message = this.$t('jsmethod.webApiCall.unknownMistake');
            return;
          }
        });
    },
    edit: function (evt, index, userId, tenantId) {
      this.userId = userId;
      this.creditTenantId = tenantId;
      this.selectedUser = index;
      this.editModal = {
        id: userId,
        tenantId: tenantId,
        displayName: this.users[index].displayName,
        isPrivate: this.users[index].isPrivate,
        status: this.users[index].status,
        ipWhitelist: this.users[index].ipWhitelist,
        merchantType: this.users[index].merchantType,
        balanceLimit: parseFloat(this.users[index].balanceLimit),
        memo: this.users[index].memo,
        isUSDT: this.users[index].isUSDT
      };
      this.groupId = this.users[index].groupId
      this.otpEnable = this.users[index].otpEnable;
      this.payoutAuthEnabled = this.users[index].payoutAuthEnabled;
      $('#editMerchantModal').modal('show');
    },
    credit: function (evt, index, tenantId) {
      this.creditAmount = 0;
      this.creditMemo = '';
      this.selectedUser = index;
      this.captcha = '';
      this.verificationTicket = '';
      this.creditTenantId = tenantId;
      this.refreshCaptcha();
      $('#creditModal').modal('show');
    },
    doUpdateTransactPassword: function () {
      var request = {
        newPassword: this.newPassword
      };

      this.errorTitle = this.$t('jsmethod.password.updateTitle');
      this.errorMsg = '';
      if (this.newPassword === null || this.newPassword == '') {
        this.errorMsg = this.$t('jsmethod.password.notNull');
      } else if (this.newPassword != this.newPasswordAgain) {
        this.errorMsg = this.$t('jsmethod.password.Inconsistent');
      }

      if (this.errorMsg != '') {
        $('#updateTransactPasswordModal').modal('hide');
        this.alert(this.errorTitle, this.errorMsg);
      } else {
        webApiCall(
          '/api/Merchants/' + this.users[this.selectedUser].id + '/UpdateTransactPassword',
          'Post',
          this,
          function (status, data) {
            this.processingRequest = false;
            this.errorTitle = this.$t('jsmethod.edit.editResultTitle');
            if (status == 200) {
              this.errorMsg = this.$t('jsmethod.edit.editSuccess');
            } else {
              this.errorMsg = this.$t('jsmethod.edit.editFail');
            }
            $('#updateTransactPasswordModal').modal('hide');
            this.alert(this.errorTitle, this.errorMsg);
          },
          request);
      }
    },
    doCreatePayment: function () {
      this.errorMsg = "";
      this.errorTitle = this.$t('merchantListModal.createPayment');
      if (this.payment.amount == '' || this.payment.amount == null) {
        this.errorMsg = this.$t('merchantListModal.amountNotNull');
      } else if (this.payment.dealerId == '' || this.payment.dealerId == null) {
        this.errorMsg = this.$t('merchantListModal.dealerIdNotNull');
      }
      if (this.errorMsg != '') {
        $('#createPayment').modal('hide');
        this.alert(this.errorTitle, this.errorMsg);
      } else {
        webApiCall(
          '/api/Payments/CreatePayment?verificationTicket=' + this.verificationTicket,
          'Post',
          this,
          function (status, data) {
            this.processingRequest = false;
            this.errorTitle = this.$t('jsmethod.add.addTitle');
            if (status == 200) {
              this.errorMsg = this.$t('jsmethod.add.addSuccess');
            } else if (data.error == "credentials_required") {
              this.errorMsg = this.$t('operators.openOtpMessage');
            } else if (data.error == "invalid_credentials") {
              this.errorMsg = this.$t('operators.otpCodeError');
            } else {
              this.errorMsg = this.$t('jsmethod.add.addFail');
            }
            $('#createPayment').modal('hide');
            this.alert(this.errorTitle, this.errorMsg);
          },
          this.payment);
      }
    },
    loadSuccesRates: function () {
      if (!this.checkSuccesRatesDateAndTime()) return;
      this.message = this.$t('jsmethod.webApiCall.loading');
      this.succesRates.startDateAndTime = this.fullStartDateTime;
      this.succesRates.endDateAndTime = this.fullEndDateTime;
      webApiCall(
        '/api/Reports/Channels?accountType=2&accountName=' + this.users[this.selectedUser].accountName + '&start=' + this.succesRates.startDateAndTime + '&end=' + this.succesRates.endDateAndTime,
        'GET',
        this,
        function (status, data) {
          this.message = '';
          if (status == 200) {
            this.succesRates.items = data.result;
            $('#showSuccesRatesModal').modal('show');
          } else {
            $('#showSuccesRatesModal').modal('hide');
            this.errorTitle = this.$t('jsmethod.webApiCall.loadingFailTitle');
            this.errorMsg = this.$t('jsmethod.webApiCall.loadLoginLogsError');
            this.alert(this.errorTitle, this.errorMsg);
          }
        });
    },
    doCreateMerchant: function () {
      this.errorMsg = '';
      this.errorTitle = this.$t('merchantListModal.doCreateMerchantTitle');
      if (this.newUser.accountName === null || this.newUser.accountName == '') {
        this.errorMsg = this.$t('login.accountNotNull');
      } else if (this.newUser.password === null || this.newUser.password == '') {
        this.errorMsg = this.$t('jsmethod.password.notNull');
      } else if (this.newUser.password != this.newUser.password1) {
        this.errorMsg = this.$t('jsmethod.password.Inconsistent');
      } else if (this.newUser.displayName == null || this.newUser.displayName == '') {
        this.errorMsg = this.$t('merchantListModal.merchantNameNotNull');
      }
      if (this.errorMsg != '') {
        $('#createMerchantModal').modal('hide');
        this.alert(this.errorTitle, this.errorMsg);
        return;
      }

      this.processingRequest = true;
      webApiCall('/api/Merchants', 'POST', this, function (status, data) {
        this.processingRequest = false;
        this.errorTitle = this.$t('merchantListModal.doCreateMerchantTitle');
        if (status == 200) {
          this.errorMsg = this.$t('merchantListModal.createMerchantSuccess');
        } else {
          this.errorMsg = this.$t('merchantListModal.createMerchantFail');
        }
        $('#createMerchantModal').modal('hide');
        this.alert(this.errorTitle, this.errorMsg);
        this.pageToken = '';
        this.loadData('');
      }, this.newUser);
    },
    editIpWhitelist: function () {
      this.editIpWhitelistData = jsonDeepClone(this.editModal.ipWhitelist);
      $('#editIpWhitelistModal').modal('show');
    },
    editIpWhitelistDelete: function (index) {
      this.editIpWhitelistData.splice(index, 1);
    },
    editIpWhitelistAdd: function () {
      this.editIpWhitelistData.push('');
    },
    doEditIpWhitelist: function () {
      this.editIpWhitelistData = this.editIpWhitelistData.filter(function (x) { return x; });
      let hasDuplicate = this.editIpWhitelistData.some(function (item, index) { return vm.editIpWhitelistData.indexOf(item, index + 1) !== -1 });
      if (hasDuplicate) {
        this.alert(this.$t('jsmethod.edit.editResultTitle'), this.$t('merchantListModal.hasDuplicate'));
      } else {
        this.editModal.ipWhitelist = jsonDeepClone(this.editIpWhitelistData);
        $('#editIpWhitelistModal').modal('hide');
      }
    },
    editPayoutIpWhitelistDelete: function (index) {
      this.editPayoutIpWhitelistData.splice(index, 1);
    },
    editPayoutIpWhitelistAdd: function () {
      this.editPayoutIpWhitelistData.push('');
    },
    doEditPayoutIpWhitelist: function () {
      this.editPayoutIpWhitelistData = this.editPayoutIpWhitelistData.filter(function (x) { return x; });
      let hasDuplicate = this.editPayoutIpWhitelistData.some((item, index) => { return this.editPayoutIpWhitelistData.indexOf(item, index + 1) !== -1 });
      if (hasDuplicate) {
        this.alert(this.$t('jsmethod.edit.editResultTitle'), this.$t('merchantListModal.hasDuplicate'));
        return;
      }

      this.processingRequest = true;
      webApiCall(
        '/api/Merchants/' + this.users[this.selectedUser].id + '/PayoutIpWhitelist?_tenantId=' + this.creditTenantId,
        'POST',
        this,
        function (status, data) {
          this.processingRequest = false;
          this.errorTitle = this.$t('jsmethod.edit.editResultTitle');
          if (status == 200) {
            this.errorMsg = this.$t('jsmethod.edit.editSuccess');
          } else {
            this.errorMsg = this.$t('jsmethod.edit.editFail');
          }
          $('#editPayoutIpWhitelistModal').modal('hide');
          this.alert(this.errorTitle, this.errorMsg);
        }, this.editPayoutIpWhitelistData);
    },
    refreshCaptcha: function () {
      this.token = new Date().getTime()
      this.captchaUrl = '/api/Home/CaptchaImage?t=' + new Date().getTime() + '&token=' + this.token;
    },
    doEdit: function () {
      webApiCall(
        '/api/Merchants/' + this.users[this.selectedUser].id + '?_tenantId=' + this.creditTenantId,
        'PUT',
        this,
        function (status, data) {
          this.processingRequest = false;
          this.errorTitle = this.$t('jsmethod.edit.editResultTitle');
          if (status == 200) {
            this.loadData('');
            this.errorMsg = this.$t('jsmethod.edit.editSuccess');
          } else {
            this.errorMsg = this.$t('jsmethod.edit.editFail');
          }
          $('#editMerchantModal').modal('hide');
          this.alert(this.errorTitle, this.errorMsg);
        }, this.editModal);
    },
    doUpdatePassword: function () {
      var request = {
        newPassword: this.newPassword
      };

      this.errorTitle = this.$t('jsmethod.password.updateTitle');
      this.errorMsg = '';
      if (this.newPassword === null || this.newPassword == '') {
        this.errorMsg = this.$t('jsmethod.password.notNull');
      } else if (this.newPassword != this.newPasswordAgain) {
        this.errorMsg = this.$t('jsmethod.password.Inconsistent');
      }

      if (this.errorMsg != '') {
        $('#updatePasswordModal').modal('hide');
        this.alert(this.errorTitle, this.errorMsg);
      } else {
        webApiCall(
          '/api/Merchants/' + this.users[this.selectedUser].id + '/UpdatePassword',
          'Post',
          this,
          function (status, data) {
            this.processingRequest = false;
            this.errorTitle = this.$t('jsmethod.edit.editResultTitle');
            if (status == 200) {
              this.errorMsg = this.$t('jsmethod.edit.editSuccess');
            } else {
              this.errorMsg = this.$t('jsmethod.edit.editFail');
            }
            $('#updatePasswordModal').modal('hide');
            this.alert(this.errorTitle, this.errorMsg);
          },
          request);
      }
    },
    doCredit: function () {
      if (this.creditAmount == 0 || this.creditMemo == '' || this.captcha == '') {
        this.errorTitle = this.$t('dealerList.addpointFail');
        this.errorMsg = this.$t('dealerList.addpointNoAmountOrNote');
        this.alert(this.errorTitle, this.errorMsg);
        return;
      }
      this.processingRequest = true;
      webApiCall(
        '/api/Merchants/' + this.users[this.selectedUser].id + '/Credit?captcha=' + this.captcha + '&token=' + this.token + '&_tenantId=' + this.creditTenantId,
        'POST',
        this,
        function (status, data) {
          this.processingRequest = false;
          this.errorTitle = this.$t('dealerList.addpointResultTitle');
          if (status == 200) {
            this.$set(this.users, this.selectedUser, data.result);
            this.errorMsg = this.$t('dealerList.addpointSuccess');
          } else {
            if (data.errorMessage == "operator does not have sufficient balance to complete the transaction") {
              this.errorMsg = this.$t('dealerList.addpointNotEnough');
            } else if (data.errorMessage == "dealer does not have sufficient balance to complete the transaction") {
              this.errorMsg = this.$t('dealerList.subpointNotEnough');
            } else if (data.error == "invalid_captcha") {
              this.errorMsg = this.$t('login.captchaError');
            } else if (data.error == "credentials_required") {
              this.errorMsg = this.$t('operators.openOtpMessage');
            } else if (data.error == "invalid_credentials") {
              this.errorMsg = this.$t('operators.otpCodeError');
            } else {
              this.errorMsg = this.$t('dealerList.addpointFail');
            }
          }
          $('#creditModal').modal('hide');
          this.alert(this.errorTitle, this.errorMsg);
        },
        {
          credit: this.creditAmount,
          memo: this.creditMemo,
          verificationTicket: this.verificationTicket
        });
    },
    createMerchant: function (evt, parentId) {
      this.newUser = {
        parentId: parentId,
        accountName: '',
        password: '',
        password1: '',
        displayName: '',
        logo: '',
        isPrivate: false,
        merchantType: 0,
        balanceLimit: 0,
        memo: ''
      };
      $('#createMerchantModal').modal('show');
    },
    doSearch: function (event) {
      this.pageToken = '';
      this.loadData('');
    },
    loadData: function (paging) {
      this.message = this.$t('jsmethod.webApiCall.loading');
      this.selectedUser = -1;
      this.upperLevels = [];
      let upBound = this.balanceUpBound ? parseFloat(this.balanceUpBound) : 0;
      let lowBound = this.balanceLowBound ? parseFloat(this.balanceLowBound) : 0;
      if (this.balanceUpBound && this.balanceLowBound) {
        if (lowBound >= upBound) {
          this.message = this.$t('merchantListModal.lowBoundThenUpBound');
          return;
        }
      }

      webApiCall(
        '/api/Merchants?pageToken=' + this.pageToken +
        '&paging=' + paging +
        '&tenantId=' + this.tenantId +
        '&accountName=' + this.accountName +
        '&displayName=' + this.displayName +
        (this.accountId == '' ? '' : '&accountId=' + this.accountId) +
        (this.parentId == '' ? '' : '&upperLevel=' + this.parentId) +
        (this.listAgent == false ? '' : '&listAgent=' + this.listAgent) +
        (this.accountStatus == 0 ? '' : '&status=' + this.accountStatus) +
        (upBound <= 0 ? '' : '&balanceUpBound=' + upBound) +
        (lowBound <= 0 ? '' : '&balanceLowBound=' + lowBound),
        'GET',
        this,
        function (status, data) {
          if (status == 200) {
            this.message = '';
            this.users = data.result.records;
            this.pageToken = data.result.pageToken;
            this.totalPages = data.result.totalPages;
            this.currentPage = data.result.page;
            this.totalRecords = data.result.totalRecords;
            this.showParentId = !this.listAgent;
            if (this.totalRecords > 0 && (this.accountId || this.parentId)) {
              this.loadMerchantRelations(this.parentId ? this.parentId : this.accountId);
            }
          } else {
            this.message = this.$t('jsmethod.webApiCall.unknownMistake');
            return;
          }
        });
    },
    saveRates: function () {
      this.processingRequest = true;
      var changes = [];
      var adjust = false;
      for (var i = 0; i < this.channels.length; ++i) {
        if (this.channels[i].adjustment != 0) {
          if (!adjust) {
            adjust = true;
          }
          changes.push(
            {
              channelId: this.channels[i].id,
              commissionRate: this.channels[i].adjustment
            });
        } else if (!adjust && (this.originalRates[this.channels[i].id] ? this.originalRates[this.channels[i].id] : 0) != this.channels[i].commissionRate) {
          changes.push(
            {
              channelId: this.channels[i].id,
              commissionRate: this.channels[i].commissionRate
            });
        }
      }
      if (changes.length == 0) {
        this.processingRequest = false;
        return;
      }
      if (adjust) {
        this.errorTitle = this.$t('merchantListModal.channeladjustment');
        webApiCall(
          '/api/Merchants/' + this.users[this.selectedUser].id + '/RateRanges/Adjust?_tenantId=' + this.creditTenantId,
          'GET',
          this,
          function (status, data) {
            if (status == 200) {
              for (let i = 0; i < changes.length; i++) {
                let adjustRate = changes[i].commissionRate;
                let rate = this.originalRates[changes[i].channelId] + adjustRate;
                let validRange = (data.result && Object.keys(data.result).length > 0) ? data.result[changes[i].channelId] : null;
                if (rate <= 0 || rate > 10000) {
                  this.errorMsg = this.$t('merchantListModal.ratesRegulateInvalid');
                  $('#channelsAndRatesModal').modal('hide');
                  this.alert(this.errorTitle, this.errorMsg);
                  this.processingRequest = false;
                  return;
                } else if (validRange && (adjustRate + validRange.upperBound > 10000 ||
                  rate < (this.users[this.selectedUser].parentId > 0 ? validRange.lowerBound : 1))) {
                  this.errorMsg = this.$t('merchantListModal.ratesRegulateFail');
                  $('#channelsAndRatesModal').modal('hide');
                  this.alert(this.errorTitle, this.errorMsg);
                  this.processingRequest = false;
                  return;
                }
              }
              webApiCall(
                '/api/Merchants/' + this.users[this.selectedUser].id + '/AdjustRates?_tenantId=' + this.creditTenantId,
                'POST',
                this,
                function (status, data) {
                  this.processingRequest = false;
                  if (status == 200) {
                    this.errorMsg = this.$t('merchantListModal.ratesRegulateSuccess');
                  } else {
                    this.errorMsg = this.$t('merchantListModal.ratesRegulateFail');
                  }
                  $('#channelsAndRatesModal').modal('hide');
                  this.alert(this.errorTitle, this.errorMsg);
                },
                changes);
            } else {
              $('#channelsAndRatesModal').modal('hide');
              this.saveError();
            }
          });
      } else {
        this.errorTitle = this.$t('merchantListModal.ratesModifyTitle');
        webApiCall(
          '/api/Merchants/' + this.users[this.selectedUser].id + '/RateRanges/Update?_tenantId=' + this.creditTenantId,
          'GET',
          this,
          function (status, data) {
            if (status == 200) {
              for (let i = 0; i < changes.length; i++) {
                let rate = changes[i].commissionRate;
                let validRange = (data.result && Object.keys(data.result).length > 0) ? data.result[changes[i].channelId] : null;
                if (rate <= 0 || rate > 10000) {
                  this.errorMsg = this.$t('merchantListModal.ratesModifyInvalid');
                  $('#channelsAndRatesModal').modal('hide');
                  this.alert(this.errorTitle, this.errorMsg);
                  this.processingRequest = false;
                  return;
                } else if (validRange && (rate < (this.users[this.selectedUser].parentId > 0 ? validRange.lowerBound : 1) ||
                  rate > validRange.upperBound)) {
                  this.errorMsg = this.$t('merchantListModal.ratesModifyFail');
                  $('#channelsAndRatesModal').modal('hide');
                  this.alert(this.errorTitle, this.errorMsg);
                  this.processingRequest = false;
                  return;
                }
              }
              webApiCall(
                '/api/Merchants/' + this.users[this.selectedUser].id + '/Rates?_tenantId=' + this.creditTenantId,
                'POST',
                this,
                function (status, data) {
                  this.processingRequest = false;
                  if (status == 200) {
                    this.errorMsg = this.$t('merchantListModal.ratesModifySuccess');
                  } else {
                    this.errorMsg = this.$t('merchantListModal.ratesModifyFail');
                  }
                  $('#channelsAndRatesModal').modal('hide');
                  this.alert(this.errorTitle, this.errorMsg);
                },
                changes);
            } else {
              $('#channelsAndRatesModal').modal('hide');
              this.saveError();
            }
          });
      }
    },
  },
  mounted() {
    this.merchantTypeList = [
      { value: true, text: this.$t('merchantListModal.typeAgent') },
      { value: false, text: this.$t('merchantListModal.typeMerchant') }
    ];
    this.accountStatusOptions = [
      { value: 0, name: this.$t("Tsearch.all") },
      { value: 1, name: this.$t("consolejs.accountStatus.Active") },
      { value: 3, name: this.$t("consolejs.accountStatus.Lockout") },
      { value: 4, name: this.$t("consolejs.accountStatus.Disabled") },
      { value: 5, name: this.$t("consolejs.accountStatus.Removed") }
    ];
  }
};
</script>

<style lang="sass">
  #error-view h1.text-h1
    font-size: 14rem !important
</style>

<style scoped>
/*
 *Content
*/
.whiteTable,
.modalTable {
  background-color: white;
  word-break: break-all;
  border: 1px solid #dee2e6;
  margin-bottom: 0;
}

.whiteTable tr td button,
.whiteTable tr td .btna {
  color: #37383a;
  background-color: white;
  border: 1px solid #DDD;
  border-radius: 5px;
  padding: 3px 10px;
  display: inline-block;
  margin: 2px 0;
  transition: all 0.15s ease;
}

.whiteTable tr> :last-child>button:hover,
.whiteTable .btna:hover {
  background-color: #FFC107;
}

.whiteTable tbody tr:nth-of-type(odd) {
  background-color: rgba(108, 108, 108, 0.07);
}

.whiteTable td,
.modalTable td {
  padding: 8px 10px;
  vertical-align: middle;
  height: 50px;
}

.whiteTable:hover tr:hover td {
  background-color: rgb(222, 226, 230);
}

.whiteTable:hover .total:hover td {
  background-color: inherit;
}

.whiteTable .total td {
  background-color: white;
  color: red;
  border-top: 3px solid #c1c1c1;
}

.whiteTable td.amount {
  word-break: keep-all;
}

.whiteTable td.datetime {
  word-break: break-word;
}

.modalTable tr> :first-child {
  background-color: rgba(108, 108, 108, 0.07);
  text-align: right;
  min-width: 8rem;
}

.modalTable .form-control {
  /*     border: 0;
        border-bottom: 1px solid #CCC;
        border-radius: 0;*/
}

.table tr td.fit {
  width: 1px;
  white-space: nowrap;
}

.search-form {
  display: flex;
  margin-bottom: 15px;
  border: 1px solid #dee2e6;
}

.search-form>p {
  background-color: #dee2e6;
  margin: 0;
  padding: 15px 20px;
  text-align: center;
  width: 150px;
}

.search-form>div {
  background-color: white;
  width: calc(100% - 150px);
}

.search-form .search-column {
  display: flex;
  align-items: center;
}

.search-form .search-column>label {
  margin: 0;
  width: 6rem;
}

.search-form .search-column>input[type=text],
.search-form .search-column>input[type=number],
.search-form .search-column>.input-group,
.search-form .search-column>select {
  width: calc(100% - 80px);
  margin: 5px 0;
}

.search-form .search-column-time {
  display: flex;
}

.search-form .search-column-time label {
  padding: 0 5px;
  margin: 0;
  align-items: center;
  display: flex;
}

.search-form .search-column-time :first-child {
  padding: 0 5px 0 0;
  white-space: nowrap;
}

.search-form .search-column-time input {
  padding: 3px 15px;
}

.search-form .selectTimeType {
  padding-top: 15px;
  border-top: 1px solid #DDD;
  margin-top: 15px;
}

.search-form .selectTimeType>select {
  width: auto !important;
  padding: 0.375rem 0.75rem !important;
}

.search-form #searchBtn,
.modal-body .search-form .searchBtn {
  width: 100%;
  background-color: #FFC107;
  border: 0;
  transition: ease 0.2s;
  padding: 10px 0;
}

.search-form #searchBtn:hover {
  background-color: orange;
}

.modal-body .search-form .selectTimeType {
  padding-top: 0px;
  border-top: 0;
  margin-top: 0;
}

@media screen and (min-width: 991px) {
  .search-form>p {
    padding-top: 24px;
  }
}

@media screen and (max-width: 991px) {
  .search-form .search-column-time {
    display: block;
  }

  .search-form .search-column-time input {
    width: 50%;
    float: left;
  }

  .search-form .search-column {
    display: block;
  }

  .search-form .search-column>input[type=text],
  .search-form .search-column>input[type=number],
  .search-form .search-column>.input-group,
  .search-form .search-column>select {
    width: 100%;
  }

  .search-form #searchBtn {
    margin-top: 15px;
  }

  .search-form .search-column>label {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .search-form {
    display: block;
  }

  .search-form>p,
  .search-form>div {
    width: 100%;
  }
}

.yellowBtn {
  background-color: #FFC107;
  transition: ease 0.2s;
  padding: 8px 20px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.yellowBtn:hover {
  background-color: orange;
}

.grayBtn {
  background-color: #e3e3e3;
  transition: ease 0.2s;
  padding: 8px 20px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.grayBtn:hover {
  background-color: #CCC;
}

.whiteBtn {
  background-color: white;
  transition: ease 0.2s;
  padding: 8px 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.whiteBtn:hover {
  background-color: #FFC107;
}

.functionButton button {
  background-color: #414244;
  border: 1px solid #DDD;
  border-radius: 0;
  margin-bottom: 10px;
  margin-top: 5px;
  transition: ease 0.2s;
  padding: 15px 35px;
  color: white;
}

.functionButton button:hover {
  background-color: black;
  padding: 15px 35px;
}

.functionButton button>i {
  margin-right: 15px;
  color: #ffc107;
}

@supports (-ms-ime-align:auto) {
  body {
    overflow-x: hidden;
  }
}

.block {
  background: #999;
  width: 100px;
  height: 100px;
  margin-top: 10px;
}

.fade-enter-active {
  animation: go 0.4s;
}

.fade-leave-active {
  animation: back 0.4s;
}

@keyframes go {
  from {
    background: #fff;
  }

  to {
    background: #999;
  }
}

@keyframes back {
  from {
    background: #999;
  }

  to {
    background: #fff;
  }
}

.red {
  color: red;
}

.green {
  color: green;
}

.gray {
  color: gray;
}

.reportCollapse {}

.reportCollapse .collapseTitleBar {
  margin: 0;
  border-right: 1px solid #DEDEDE;
  cursor: pointer;
}

.reportCollapse .collapseTitleBar>div {
  background-color: white;
  border: 1px solid #DEDEDE;
  border-right: 0;
  padding: 5px 10px;
}

.reportCollapse .collapseTitleBar>div>div {
  padding: 5px 0;
}

.reportCollapse .collapseTitleBar .name {
  display: flex;
  align-items: center;
  max-width: 100%;
  flex: auto;
  transition: all 0.2s ease;
  height: 67px;
  background-color: #37383a;
  color: white;
}

.reportCollapse .collapseTitleBar .navbar {
  display: flex;
  align-items: center;
  max-width: 100%;
  flex: auto;
  transition: all 0.2s ease;
  height: 65px;
  background-color: #37383a;
  color: white;
}

.reportCollapse .collapseTitleBar .col-2 {
  padding: 0;
  text-align: center;
  transition: all 0.2s ease;
  flex: none;
  max-width: 0%;
  border: 0;
  position: absolute;
  opacity: 0;
  right: 0;
}

.reportCollapse .collapseTitleBar .title {
  border-bottom: 1px solid #DEDEDE;
  background-color: #f4f8fb;
  font-weight: bold;
}

.reportCollapse .collapsed .col-2 {
  color: red;
  display: block;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  border: 1px solid #DEDEDE;
  border-right: 0;
  color: black;
  position: relative;
  opacity: 100;
}

.reportCollapse .collapsed .name {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  background-color: white;
  color: black;
}

.reportCollapse .collapsed .name.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.reportCollapse .collapsed .navbar {
  max-width: 100%;
  background-color: white;
  color: black;
}

.reportCollapse .total {
  cursor: default;
}

.reportCollapse .total .title {
  background-color: #fbf4f4;
  color: black
}

.reportCollapse .total div {
  color: #d80808;
}

.reportCollapse .total .name {
  color: #d80808;
  font-weight: bold;
}

/*scrollbar style*/
::-webkit-scrollbar {
  width: 6px;
  height: 16px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

/*card callouts*/
.callout {
  border-left: 6px solid #eee;
}

.callout-trace {
  border-left-color: #6c757d !important;
}

.callout-debug {
  border-left-color: #28a745 !important;
}

.callout-info {
  border-left-color: #5bc0de !important;
}

.callout-warn {
  border-left-color: #ffc107 !important;
}

.callout-error {
  border-left-color: #fd7e14 !important;
}

.callout-critical {
  border-left-color: #dc3545 !important;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}

.warnOption {
  background-color: darkred;
  font-weight: bold;
  color: white;
}
</style>