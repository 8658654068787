import Vue from 'vue'
import VueI18n from 'vue-i18n'

import cn from './cn/cn.json'

Vue.use(VueI18n)



const lang = 'cn'


const i18n = new VueI18n({
  locale: 'cn', // 預設語言
  fallbackLocale: 'cn', // 備用語言
  messages: cn
})

export default i18n